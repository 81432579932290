<template>
    <div v-infinite-scroll="loadMore" infinite-scroll-distance="25">
        <div v-if="isFetching" class="l-padded">
            <VSpinner size="medium" :line-fg-color="spinnerColor" :speed="1" />
        </div>

        <div
            v-else-if="!list.length"
            class="l-padded l-inline l-center t-subtle t-small"
        >
            <span>
                {{ $t('shared.noSearchResult') }}
            </span>
        </div>

        <table v-else>
            <thead>
                <tr>
                    <th>
                        {{ $t('policy') }}

                        <IconButton @click="handleSort('policy')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'policy' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('shared.asset') }}

                        <IconButton @click="handleSort('asset')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'asset' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('time') }}

                        <IconButton @click="handleSort('timestamp')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'timestamp' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>{{ $t('value') }}</th>

                    <th>
                        {{ $t('documents') }}

                        <IconButton @click="handleSort('documents')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'documents' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>{{ $t('notes') }}</th>
                </tr>
            </thead>

            <template v-for="item in listPortion">
                <tr
                    :key="item.id"
                    class="clickable"
                    @click="selected = selected === item.id ? null : item.id"
                >
                    <td>
                        <span v-if="maintenancePolicyMap[item.policy]">
                            {{ maintenancePolicyMap[item.policy].name }}
                        </span>
                    </td>

                    <td>
                        <div
                            v-if="assetMap[item.asset]"
                            class="l-gap-1 l-inline l-center-v "
                        >
                            <AssetAvatar
                                :tracker="assetMap[item.asset]"
                                :size="15"
                            />

                            <span>
                                {{ assetMap[item.asset].asset_details.name }}
                            </span>
                        </div>
                    </td>

                    <td>
                        {{ formatTimestamp(item.timestamp) }}
                    </td>

                    <td>
                        {{ item.value }}
                    </td>

                    <td>
                        {{ item.documents.length }}
                    </td>

                    <td>
                        {{ item.notes_preview }}
                    </td>
                </tr>

                <tr v-if="item.id === selected" :key="`${item.id}-notes`">
                    <td colspan="6">
                        <div class="separator">
                            <div class="u-bb" />
                        </div>

                        <div class="notes" v-html="item.notes" />

                        <template v-if="item.documents.length">
                            <div class="l-padded-y">
                                <div class="u-bb" />
                            </div>

                            <div class="l-stack l-gap-1">
                                <a
                                    v-for="doc in item.documents"
                                    :key="doc.id"
                                    class="l-inline l-center-v l-gap-1"
                                    :href="doc.file_url"
                                    target="_blank"
                                >
                                    <FileIcon width="1em" height="1em" />

                                    <span>{{ doc.name }}</span>
                                </a>
                            </div>
                        </template>
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { sanitize } from 'dompurify'
import infiniteScroll from 'vue-infinite-scroll'
import moment from 'moment-timezone'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, measurementHelper } from '@/utils'
import AssetAvatar from '@/components/AssetAvatar'
import FileIcon from '@/components/icons/FileIcon'
import IconButton from '@/components/IconButton'
import SortArrowIcon from '@/components/icons/SortArrowIcon'

const defaultScrollLimit = 15
const defaultSortColumn = 'timestamp'
const defaultSortDirection = 1

const converters = {
    duration: value => Math.round(value / 60 / 60 / 24),
    odometer: measurementHelper.converters.odometer,
    running_time: value => Math.round(value / 60 / 60),
}

export default {
    name: 'MaintenanceHistoryTable',
    components: {
        AssetAvatar,
        FileIcon,
        IconButton,
        SortArrowIcon,
        VSpinner,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        filterAsset: {
            type: Array,
            default: () => [],
        },
        filterPolicy: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            isFetching: false,
            list: [],
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            selected: null,
            sortBy: defaultSortColumn,
            sortDirection: defaultSortDirection,
            spinnerColor: process.env.VUE_APP_COLOR_PRIMARY,
            units: {
                duration: count => this.$tc('day', count),
                odometer: () => measurementHelper.units.odometer,
                running_time: count => this.$tc('hour', count),
            },
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('maintenance', ['maintenancePolicyMap']),
        assetMap() {
            return this.trackers.reduce(
                (acc, cur) => ({ ...acc, [cur.asset]: cur }),
                {}
            )
        },
        listFiltered() {
            let result = this.list

            if (this.sortBy) {
                const propertyGetterMap = {
                    asset: item => this.assetMap[item.asset].asset_details.name,
                    default: item => item[this.sortBy],
                    documents: item => item.documents.length,
                    policy: item => this.maintenancePolicyMap[item.policy].name,
                }

                const propertyGetter =
                    propertyGetterMap[this.sortBy] || propertyGetterMap.default

                result = [...result].sort((a, b) => {
                    const aValue = propertyGetter(a)
                    const bValue = propertyGetter(b)

                    if (this.sortBy === 'timestamp') {
                        return (
                            this.sortDirection *
                            (new Date(bValue).getTime() -
                                new Date(aValue).getTime())
                        )
                    } else if (aValue === bValue) {
                        return 0
                    } else if (aValue == null) {
                        return 1
                    } else if (bValue == null) {
                        return -1
                    } else if (typeof aValue === 'string') {
                        return aValue.localeCompare(bValue) * this.sortDirection
                    }

                    return aValue > bValue
                        ? this.sortDirection
                        : -this.sortDirection
                })
            }

            return result
        },
        listPortion() {
            return this.listFiltered.length === this.list.length
                ? this.listFiltered.slice(0, this.scrollLimit)
                : this.listFiltered
        },
    },
    watch: {
        filterAsset() {
            this.fetchMaintenanceHistory()
        },
        filterPolicy() {
            this.fetchMaintenanceHistory()
        },
    },
    mounted() {
        this.fetchMaintenanceHistory()
    },
    methods: {
        async fetchMaintenanceHistory() {
            this.isFetching = true
            const { data } = await httpHelper.get(
                '/maintenance-history-records/',
                {
                    params: {
                        asset: this.filterAsset.map(({ asset }) => asset),
                        policy: this.filterPolicy.map(({ id }) => id),
                    },
                }
            )
            this.list = data.results.map(item => {
                item.notes = sanitize(item.notes)
                item.notes_preview = sanitize(item.notes, {
                    USE_PROFILES: { html: false },
                })
                if (item.notes_preview?.length > 20) {
                    item.notes_preview =
                        item.notes_preview.slice(0, 20).trim() + '...'
                }
                if (typeof item.performed_value === 'number') {
                    const type = this.maintenancePolicyMap[item.policy]?.type
                    if (type && converters[type]) {
                        item.value = converters[type](item.performed_value)
                    }
                    if (type && this.units[type]) {
                        item.value = `${item.value} ${this.units[type](
                            item.value
                        )}`
                    }
                }
                return item
            })
            this.isFetching = false
        },
        formatTimestamp(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY HH:mm')
        },
        handleSort(column) {
            if (this.sortBy !== column || this.sortDirection !== 1) {
                this.sortBy = column
                this.sortDirection = 1
            } else if (this.sortDirection === 1) {
                this.sortDirection = -1
            }
        },
        loadMore() {
            if (this.scrollLimit < this.list.length) {
                this.scrollLimit += this.scrollStep
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "day": "day | days",
        "documents": "Attachments",
        "hour": "hour | hours",
        "notes": "Notes",
        "policy": "Maintenance policy",
        "time": "Time",
        "value": "Value"
    },
    "de": {
        "day": "Tag | Tage",
        "documents": "Anhänge",
        "hour": "Stunde | Stunden",
        "notes": "Notizen",
        "policy": "Wartungsplan",
        "time": "Zeit",
        "value": "Wert"
    },
    "fr": {
        "day": "jour | jours",
        "documents": "Pièces jointes",
        "hour": "heure | heures",
        "notes": "Notes",
        "policy": "Politique de maintenance",
        "time": "Temps",
        "value": "Valeur"
    },
    "it": {
        "day": "giorno | giorni",
        "documents": "File allegati",
        "hour": "ora | ore",
        "notes": "Note",
        "policy": "Politica di manutenzione",
        "time": "Tempo",
        "value": "Valore"
    }
}
</i18n>

<style lang="scss" scoped>
table {
    display: block;
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    thead {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;

        .icon-button {
            margin-left: 5px;
        }
    }

    tr {
        &.clickable {
            &:hover {
                background-color: $color-gray-lighter;
            }

            &:not(:first-of-type) {
                td {
                    border-top: $style-border;
                }
            }

            td {
                padding-top: 1rem;
            }
        }

        th {
            padding: 1rem;
            text-align: left;
            border-bottom: $style-border;
        }

        td {
            padding: 0 1rem 1rem;
        }

        th,
        td {
            white-space: nowrap;

            &:first-child {
                padding-left: 2rem;
            }

            &:last-child {
                padding-right: 2rem;
            }

            &:not(:last-child) {
                width: 1%;
            }
        }
    }

    a {
        span {
            text-decoration: underline;
        }

        &:hover {
            span {
                text-decoration: none;
            }
        }
    }

    .notes {
        white-space: pre;

        ol,
        ul {
            padding-left: 1.5em;
        }
    }

    .separator {
        padding: 0 0 1rem;
    }
}
</style>
